import type { Site_Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelMedium } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Grid from "../../Grid";
import Text from "../../Text";

interface Props {
  data: NonNullable<Site_Navigation_FullFragment["items"]>[0];
}

const Links: FunctionComponent<Props> = ({ data }) => {
  const links = data.sections?.[0]?.subsections?.[0]?.links;

  return (
    <Grid
      css={css`
        align-self: start;
      `}
      gap={"1rem"}
    >
      {data.label && (
        <Text as={"h3"} color={"white"} size={"xsmall"} weight={"semibold"}>
          {data.label}
        </Text>
      )}

      <ul
        css={css`
          display: grid;
          gap: 1rem;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links?.map((link) => {
          const { label, target, url } = link;

          return (
            <li key={label}>
              <Anchor
                color={"gray"}
                hoverColor={colorSteelMedium}
                href={url || "/"}
                target={target || undefined}
              >
                <Text as={"span"} color={"white"} size={"xsmall"}>
                  {label}
                </Text>
              </Anchor>
            </li>
          );
        })}
      </ul>
    </Grid>
  );
};

export default Links;
