import type { Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { layoutBannerHeight } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Branding from "../../Branding";
import Navigation from "../../Navigation";

interface Props {
  /** Specifies "light" (blue text) or "dark" (white text). */
  mode?: "light" | "dark";

  /** Data for the site nav */
  navigation: Navigation_FullFragment;
}

/**
 * A layout container for navigation and 10x branding.
 */
const Banner: FunctionComponent<Props> = ({ mode = "dark", navigation }) => {
  return (
    <div
      css={css`
        align-items: center;
        box-sizing: border-box;
        display: flex;
        gap: 56px;
        height: ${layoutBannerHeight};
        margin: 0 auto;
        max-width: 1200px;
        padding: 1rem 0;
        width: 100%;
      `}
    >
      <Branding height={"2rem"} mode={mode} />

      <Navigation data={navigation} mode={mode} />
    </div>
  );
};

export default Banner;
