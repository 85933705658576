import type { Site_Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarker } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Flex from "../../Flex";
import Grid from "../../Grid";
import { SpanifiedText } from "../../SpanifiedText";
import Text from "../../Text";

interface Props {
  data: NonNullable<Site_Navigation_FullFragment["items"]>[0];
}

const ContactLinks: FunctionComponent<Props> = ({ data }) => {
  const links = data.sections?.[0]?.subsections?.[0]?.links;
  const email = links?.find((link) => link.url);

  return (
    <Grid
      css={css`
        align-self: start;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid ${colorSteelDarker};
        width: 100%;
      `}
      gap={"1rem"}
    >
      {data.label && (
        <Text as={"h3"} color={"white"} size={"xsmall"} weight={"semibold"}>
          {data.label}
        </Text>
      )}

      <Flex
        css={css`
          display: flex;
          gap: 1.5rem;
        `}
      >
        <Anchor
          color={"white"}
          href={email?.url || "/"}
          target={email?.target || undefined}
        >
          <Text as={"span"} color={"white"} size={"xsmall"} weight={"medium"}>
            {email?.label}
          </Text>
        </Anchor>
        <Anchor href={"tel:+19254017300"}>
          {/* 
              This is necessary to prevent a Google AdWords script that we do not control from adding addtional `+` symbols 
              at the start of the phone number every time the page comes into focus.
            */}
          <SpanifiedText as={"span"} color={"white"} size={"xsmall"}>
            {"+1 925 401 7300"}
          </SpanifiedText>
        </Anchor>
      </Flex>
    </Grid>
  );
};

export default ContactLinks;
