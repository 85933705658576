import type { ThroughputOption } from "@10x/cms/model/product-comparison";
import { borderLighter, borderStandard } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import { useEffect, useState } from "react";

import Flex from "../../Flex";
import Grid from "../../Grid";
import Image from "../../Image";
import Switch from "../../Switch";
import Text from "../../Text";
import Segment from "../SegmentedControl/Segment";

interface Props {
  options: ThroughputOption[];
}

const ThroughputOptions = ({ options }: Props) => {
  const [active, setActive] = useState(options[0].name);
  const [showOption, setShowOption] = useState(false);

  const handleSegmentClick = (name: string) => {
    setActive(name);
  };

  useEffect(() => {
    setShowOption(false);
    setActive(options[0].name);
  }, [options]);

  const activeImage = showOption
    ? options.find((option) => option.name === active)?.optionalImage
    : options.find((option) => option.name === active)?.image;

  return (
    <Grid
      css={css`
        @media (max-width: 900px) {
          gap: 2rem;
        }
      `}
    >
      <Flex
        css={css`
          height: 34px;
          border-bottom: ${borderLighter};
          padding-bottom: 0.75rem;
          @media (max-width: 900px) {
            flex-wrap: wrap;
          }
        `}
        gap={"1rem"}
      >
        <Flex
          css={css`
            box-shadow: 0px 1.718px 4.294px 0px rgba(0, 0, 0, 0.06);
            outline: ${borderStandard};
            border-radius: 3rem;
            width: max-content;
          `}
        >
          {options.map((tab) => {
            return (
              <Segment
                active={active === tab.name}
                css={css`
                  padding: 0 0.75rem;
                  height: 34px;
                `}
                id={tab.name}
                key={tab.name}
                onClick={() => handleSegmentClick(tab.name)}
              >
                <Text
                  as={"span"}
                  color={"inherit"}
                  size={"small"}
                  weight={"medium"}
                >
                  {tab.name}
                </Text>
              </Segment>
            );
          })}
        </Flex>
        {options.some((option) => option.optionalImage) ? (
          <Flex alignItems={"center"} gap={".5rem"}>
            <Switch
              checked={showOption}
              onClick={() => setShowOption(!showOption)}
            />
            <Text as={"span"} size={"small"} weight={"medium"}>
              {"Multiplexed"}
            </Text>
          </Flex>
        ) : null}
      </Flex>
      <Image src={activeImage || "placeholder.jpg"} />
    </Grid>
  );
};

export default ThroughputOptions;
