import {
  borderLighter,
  borderRadiusMedium,
  colorSteelDark,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";

import Grid from "../../Grid";
import Text from "../../Text";
import type { AssayComparison, AssayGroup, Assays, AssaySlug } from "../types";
import { getAssay, getPlatformTag } from "../utils";
import Blank from "./Blank";
import Feature from "./Feature";
import List from "./List";
import Overview from "./Overview";
import Row from "./Row";
import Select from "./Select";
import Throughput from "./Throughput";

interface Props {
  assays: Assays;
  comparisons: AssayComparison;
  setActive: (slug: AssayGroup) => void;
  setComparisons: (assays: AssayComparison) => void;
}

const CompareTable = ({
  assays,
  comparisons,
  setActive,
  setComparisons,
}: Props) => {
  const handleSelectChange = (slug: AssaySlug, index: number) => {
    const updatedComparisons = [...comparisons];
    updatedComparisons[index] = getAssay(assays, slug);
    setActive("all");
    setComparisons(updatedComparisons);
  };

  return (
    <>
      <div
        css={css`
          background: #ebf4fc;
          padding: 0 1rem;
          position: sticky;
          top: 0;
          z-index: 10;
        `}
      >
        <div
          css={css`
            max-width: 1200px;
            margin: 0 auto;
          `}
        >
          <Row
            css={css`
              padding: 0;
            `}
          >
            {comparisons.map((assay, index) => {
              const { platform, slug } = assay || {};
              return (
                <div key={`${slug}-${index}`}>
                  <Grid
                    css={css`
                      padding: 1rem 0;
                    `}
                    gap={"10px"}
                  >
                    {platform ? (
                      <Text
                        as={"span"}
                        color={"white"}
                        css={css`
                          background: ${getPlatformTag(platform).color};
                          border-radius: ${borderRadiusMedium};
                          padding: 2px 6px;
                          text-transform: uppercase;
                          width: max-content;
                        `}
                        size={"xxsmall"}
                        weight={"semibold"}
                      >
                        {getPlatformTag(platform).name}
                      </Text>
                    ) : (
                      <div
                        css={css`
                          height: 1.5rem;
                        `}
                      />
                    )}
                    <Select
                      onChange={({ value }) => handleSelectChange(value, index)}
                      options={assays.map((item) => ({
                        label: item.name,
                        value: item.slug,
                      }))}
                      placeholder={"Select an assay to compare"}
                      value={{
                        label: assay?.name || "Select an assay to compare",
                        value: slug || "",
                      }}
                    />
                  </Grid>
                </div>
              );
            })}
          </Row>
        </div>
      </div>

      <Grid
        css={css`
          max-width: 1200px;
          margin: 0 auto;
          padding: 0 1rem 6rem;
          @media (max-width: 900px) {
            gap: 0;
          }
        `}
        gap={"3.25rem"}
      >
        {comparisons.every((item) => item === undefined) ? (
          <Text
            as={"div"}
            css={css`
              color: ${colorSteelDark};
              text-align: center;
              height: 20vh;
              display: flex;
              align-items: center;
              justify-content: center;
            `}
            size={"large"}
            weight={"regular"}
          >
            {"No assays selected yet"}
          </Text>
        ) : (
          <>
            <Row
              css={css`
                padding-top: 0;
              `}
            >
              {comparisons.map((assay, index) => {
                return assay ? (
                  <Overview
                    {...assay}
                    key={`${assay.slug}-overview-${index}`}
                  />
                ) : (
                  <Blank key={`overview-blank-${index}`} marker={false} />
                );
              })}
            </Row>

            <Row label={"Primary Capability"}>
              {comparisons.map((assay, index) => {
                return assay?.baseAnalysis ? (
                  <Grid
                    css={css`
                      border-top: ${borderLighter};
                      padding-top: 10px;
                    `}
                    gap={"28px"}
                    gridAutoRows={"max-content"}
                    key={`${assay.slug}-base-${index}}`}
                  >
                    {assay.baseAnalysis.map((item, index) => {
                      return (
                        <Feature {...item} key={`${item.name}-base-${index}`} />
                      );
                    })}
                  </Grid>
                ) : (
                  <Blank key={`base-blank-${index}}`} />
                );
              })}
            </Row>

            {comparisons.some((item) => item?.multiomicCapabilities?.length) ? (
              <Row label={"Multiomic Capabilities"}>
                {comparisons.map((assay, index) => {
                  return assay?.multiomicCapabilities ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"28px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-multiomic-${index}}`}
                    >
                      {assay.multiomicCapabilities.length ? (
                        assay.multiomicCapabilities.map((item, index) => {
                          return (
                            <Feature
                              {...item}
                              key={`${item.name}-multiomic-${index}`}
                            />
                          );
                        })
                      ) : (
                        <Blank key={`multiomic-${index}}`} />
                      )}
                    </Grid>
                  ) : (
                    <Blank key={`multiomic-assay-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.compatibleSpecies?.length) ? (
              <Row label={"Compatible Species"}>
                {comparisons.map((assay, index) => {
                  return assay?.compatibleSpecies ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"10px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-species-${index}}`}
                    >
                      {assay.compatibleSpecies.map((item, index) => {
                        return (
                          <List
                            {...item}
                            key={`${item.name}-species-${index}`}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`species-blank-${index}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.compatibleSampleTypes?.length) ? (
              <Row label={"Compatible Sample Types"}>
                {comparisons.map((assay, index) => {
                  return assay?.compatibleSampleTypes ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"10px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-input-${index}}`}
                    >
                      {assay.compatibleSampleTypes.map((item, index) => {
                        return (
                          <List {...item} key={`${item.name}-input-${index}`} />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`input-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.assayInput?.length) ? (
              <Row label={"Assay Input"}>
                {comparisons.map((assay, index) => {
                  return assay?.assayInput ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"10px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-input-${index}}`}
                    >
                      {assay.assayInput.map((item, index) => {
                        return (
                          <List {...item} key={`${item.name}-input-${index}`} />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`input-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.resolution?.length) ? (
              <Row label={"Resolution"}>
                {comparisons.map((assay, index) => {
                  return assay?.resolution ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"28px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-resolution-${index}}`}
                    >
                      {assay.resolution.map((item, index) => {
                        return (
                          <Feature
                            {...item}
                            key={`${item.name}-resolution-${index}`}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`resolution-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.throughputOptions?.length) ? (
              <Row label={"Run Configuration Options"}>
                {comparisons.map((assay, index) => {
                  return assay?.throughputOptions ? (
                    <Throughput
                      key={`throughput-${index}`}
                      options={assay.throughputOptions}
                    />
                  ) : (
                    <Blank key={`throughput-blank-${index}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.compatibleInstruments?.length) ? (
              <Row label={"Compatible Instruments"}>
                {comparisons.map((assay, index) => {
                  return assay?.compatibleInstruments ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"28px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-instruments-${index}`}
                    >
                      {assay?.compatibleInstruments.map((item, index) => {
                        return (
                          <Feature
                            {...item}
                            key={`${item.name}-instruments-${index}`}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`instruments-blank-${index}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.includedAnalysis?.length) ? (
              <Row label={"Included Data Processing & Analysis Software"}>
                {comparisons.map((assay, index) => {
                  return assay?.includedAnalysis ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"28px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-software-${index}}`}
                    >
                      {assay?.includedAnalysis.map((item, index) => {
                        return (
                          <Feature
                            {...item}
                            key={`${item.name}-software-${index}`}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Blank key={`software-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}

            {comparisons.some((item) => item?.includedVisualization?.length) ? (
              <Row label={"Included Interactive Visualization Tools"}>
                {comparisons.map((assay, index) => {
                  return assay?.includedVisualization ? (
                    <Grid
                      css={css`
                        border-top: ${borderLighter};
                        padding-top: 10px;
                      `}
                      gap={"28px"}
                      gridAutoRows={"max-content"}
                      key={`${assay.slug}-visualization-${index}}`}
                    >
                      {assay.includedVisualization.length ? (
                        assay.includedVisualization.map((item, index) => {
                          return (
                            <Feature
                              {...item}
                              key={`${item.name}-vis-${index}`}
                            />
                          );
                        })
                      ) : (
                        <Blank key={`vis-${index}}`} />
                      )}
                    </Grid>
                  ) : (
                    <Blank key={`vis-blank-${index}}`} />
                  );
                })}
              </Row>
            ) : null}
          </>
        )}
      </Grid>
    </>
  );
};

export default CompareTable;
