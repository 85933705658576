import type { Site_Navigation_FullFragment } from "@10x/types/__generated__/graphql-types";
import { colorSteelDarker, colorWhite } from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent } from "react";

import Anchor from "../../Anchor";
import Icon from "../../Icon";
import type { IconSource } from "../../Icon/types";
import Text from "../../Text";

interface Props {
  data: NonNullable<Site_Navigation_FullFragment["items"]>[0];
}

const icons: Record<string, [IconSource, string]> = {
  facebook: ["facebook", "Facebook"],
  linkedin: ["linkedin", "LinkedIn"],
  twitter: ["twitter", "Twitter"],
  youtube: ["youtube", "Youtube"],
};

const IconLinks: FunctionComponent<Props> = ({ data }) => {
  const links = data?.sections?.[0]?.subsections?.[0]?.links;

  return (
    <div
      css={css`
        padding: 1.5rem 0;
        border-bottom: 1px solid ${colorSteelDarker};
      `}
    >
      {data.label && (
        <Text
          as={"h3"}
          color={"white"}
          css={css`
            margin-bottom: 1rem;
          `}
          size={"xsmall"}
          weight={"semibold"}
        >
          {data.label}
        </Text>
      )}

      <ul
        css={css`
          display: flex;
          list-style: none;
          margin: 0;
          padding: 0;
        `}
      >
        {links?.map((link) => {
          const { label, target, url } = link;

          if (!label || !url) {
            return null;
          }

          const [iconName, title] = icons[label];

          return (
            <li key={title}>
              <Anchor
                color={"gray"}
                hoverColor={colorWhite}
                href={url}
                target={target || undefined}
                title={title}
                underlineOnHover={true}
              >
                <Icon
                  color={"inherit"}
                  css={{ marginRight: "16px" }}
                  size={"20px"}
                  source={iconName}
                />
              </Anchor>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default IconLinks;
