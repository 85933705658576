import { css } from "@emotion/react";
import type { Property } from "csstype";
import type { ReactNode } from "react";

import type { WithResponsive } from "../../utils/media-queries";
import { generateResponsiveCSS } from "../../utils/media-queries";

interface GridProps {
  alignContent?: WithResponsive<Property.AlignContent>;
  alignItems?: WithResponsive<Property.AlignItems>;
  children?: ReactNode;
  className?: string;
  gap?: WithResponsive<Property.Gap>;
  gridAutoColumns?: WithResponsive<Property.GridAutoColumns>;
  gridAutoFlow?: WithResponsive<Property.GridAutoFlow>;
  gridAutoRows?: WithResponsive<Property.GridAutoRows>;
  gridTemplateColumns?: WithResponsive<Property.GridTemplateColumns>;
  gridTemplateRows?: WithResponsive<Property.GridTemplateRows>;
  justifyContent?: WithResponsive<Property.JustifyContent>;
  justifyItems?: WithResponsive<Property.JustifyItems>;
}
function Grid({ children, className, ...styles }: GridProps) {
  const cssString = generateResponsiveCSS({ ...styles });
  return (
    <div
      className={className}
      css={css`
        display: grid;
        ${cssString}
      `}
    >
      {children}
    </div>
  );
}

export default Grid;
