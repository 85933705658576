import {
  colorBlueMedium,
  colorGreen,
  colorSteelLighter,
  colorWhite,
} from "@10xdev/design-tokens";
import { css } from "@emotion/react";
import type { FunctionComponent, KeyboardEvent } from "react";

type SwitchColor = "green" | "blue";
interface Props {
  borderWidth?: number;
  checked?: boolean;
  color?: SwitchColor;
  height?: number;
  label?: string;
  onChange?: () => void;
  onClick?: () => void;
  onTransitionEnd?: () => void;
}

const transitionProps = "0.2s ease-out";

const Switch: FunctionComponent<Props> = ({
  borderWidth = 2,
  color = "green",
  height = 28,
  label,
  onClick,
  onTransitionEnd,
  checked = false,
}) => {
  const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (event.key === "Enter") {
      onClick && onClick();
    }
  };

  const getColor = (color: SwitchColor) => {
    const colors = {
      blue: colorBlueMedium,
      green: colorGreen,
    };
    return colors[color];
  };

  return (
    <div
      aria-label={label}
      css={css`
        background-color: ${checked ? getColor(color) : colorWhite};
        border-color: ${checked ? getColor(color) : colorSteelLighter};
        border-style: solid;
        border-width: ${borderWidth}px;
        border-radius: ${height}px;
        box-sizing: border-box;
        cursor: pointer;
        height: ${height}px;
        position: relative;
        transition: background-color ${transitionProps},
          border-color ${transitionProps};
        width: ${height * 1.5}px;
      `}
      onClick={onClick}
      onKeyPress={handleKeyPress}
      role={"button"}
      tabIndex={0}
    >
      <input
        aria-checked={checked}
        css={css`
          display: none;
          pointer-events: none;
        `}
        defaultChecked={checked}
        type={"checkbox"}
      />
      <div
        css={css`
          background-color: ${colorWhite};
          border-radius: ${height}px;
          box-shadow: 0px 1px 3px rgba(35, 43, 84, 0.25),
            0px 2px 8px rgba(35, 43, 84, 0.25);
          box-sizing: border-box;
          height: ${height - borderWidth * 2}px;
          left: ${checked ? height / 2 : 0}px;
          pointer-events: none;
          position: absolute;
          transition: left ${transitionProps};
          width: ${height - borderWidth * 2}px;
        `}
        data-testid={"switch-handle"}
        onTransitionEnd={onTransitionEnd}
      />
    </div>
  );
};

export default Switch;
